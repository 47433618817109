<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row flex-grow-1 justify-space-between">
          <h3
            class="font-weight-bold blue-grey--text mt-2 font font-md font-weight-medium text-uppercase"
          >
            All Tickets
          </h3>
        </div>
      </v-col>
      <v-col cols="12" sm="12">
        <div class="d-flex flex-row flex-grow-1 justify-end">
          <v-text-field
            solo
            rounded
            placeholder="Search through tickets using phone number or ticket number ..."
            flat
            append-icon="search"
            v-model="search"
            hide-details
            class="box-shadow-light font font-sm"
            style="min-width: 400px"
          >
          </v-text-field>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn
                :loading="pageLoading"
                class="mx-2 mt-1"
                @click="$store.dispatch('tickets/list', '?page=1&size=30')"
                v-on="on"
                small
                fab
                icon
              >
                <i class="material-icons-outlined">refresh</i>
              </v-btn>
            </template>
            <span class=" font font-sm">Get latest tickets</span>
          </v-tooltip>
          <v-btn
            :to="{ name: 'admin.verify.tickets' }"
            color="primary"
            class=" mt-2"
          >
            <span class=" font font-weight-medium  font-sm"
              >Verify Tickets</span
            >
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-data-table
          :headers="headers"
          :items="tickets"
          :loading="pageLoading"
          item-key="branch"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font text-capitalize box-shadow-light mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50],
          }"
        >
          <template #item.createdAt="{item}">
            <span class=" font font-weight-medium font-sm">
              {{ item.createdAt | dateFormat }}
            </span>
          </template>
          <template #item.msisdn="{item}">
            <v-chip label small>
              <span class=" font font-weight-bold  font-sm">{{
                item.msisdn
              }}</span>
            </v-chip>
          </template>
          <template #item.ticketNumber="{item}">
            <v-chip label small color="primary">
              <span class=" font font-weight-medium font-sm">{{
                item.ticketNumber
              }}</span>
            </v-chip>
          </template>
          <template #item.isVerified="{item}">
            <v-chip label small :color="item.isVerified ? 'success' : 'error'">
              <span class=" font font-weight-medium font-sm text-uppercase">{{
                item.isVerified ? 'Verified' : 'Not Verified'
              }}</span>
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <BulkUploadDialog
      :state="dialog"
      @actions="open"
      @uploadFile="uploadFile"
      :loading="isLoading"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';
import moment from 'moment';
export default {
  name: 'Transactions',
  components: {
    BulkUploadDialog: () => import(`../../../component/BulkUploadDialog`),
  },
  data() {
    return {
      search: '',
      options: {
        page: 1,
        itemsPerPage: 30,
      },
      menu: false,
      dates: [],
      chosenDateRange: [],
    };
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('Do MMM,YYYY HH:mm:ss');
    },
    currencyFormat(value) {
      return new Intl.NumberFormat('en-GB', {
        currency: 'GHS',
        style: 'currency',
        minimumFractionDigits: 2,
      }).format(value);
    },
  },

  computed: {
    ...mapGetters({
      pageLoading: 'getIsPageLoading',
      paginate: 'tickets/getPagination',
      tickets: 'tickets/getAllTickets',
      isLoading: 'getIsLoading',
    }),
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    transactionsList() {
      return this.transactions.filter(searchTerm => {
        return (
          searchTerm.sequenceID
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
        );
      });
    },
    headers() {
      return [
        {
          text: 'Date',
          value: 'createdAt',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Account Number',
          value: 'msisdn',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Ticket Number',
          value: 'ticketNumber',
          filterable: true,
          sortable: false,
        },
        {
          text: 'Verification Status',
          value: 'isVerified',
          filterable: true,
          sortable: false,
        },
      ];
    },
    dialog() {
      return this.$store.getters['getDialogState']('add');
    },
  },
  methods: {
    open(payload) {
      this.$store.dispatch('updateDialog', payload, { root: true });
    },
    uploadFile(file) {
      this.$store.dispatch('balances/uploadBalances', file);
    },
  },
  watch: {
    search: debounce(function(searchTerm) {
      if (searchTerm !== '') {
        const query = `?searchTerm=${searchTerm}&page=1&size=30`;
        this.$store.dispatch('tickets/searchList', query);
      } else {
        this.$store.dispatch('tickets/list', '?page=1&size=30');
      }
    }, 800),
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch('tickets/list', query);
      },
      deep: true,
    },
  },
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch('tickets/list', query);
  },
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  alignt-content: stretch;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
